import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../Footer";
import Spinner from "../facultydashboard/formGroup/Spinner";
import Topbar from "../landing/Topbar";
import AlumniFeedBack from "./AlumniFeedBack";
import AlumniList from "./AlumniList";
import alumni from "../../assests/alumni2.jpg"
function Alumni() {
  const [datas, setData] = React.useState({});
  const [loader, setLoader] = useState(true);
  // console.log(datas);
  const siteData = () => {
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0]);
        setLoader(false)
        // .
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  React.useEffect(() => {
    siteData();
  }, []);
  const [step, setStep] = useState('step1')
  // console.log(useState)
  // console.log(step)
  const stepChanges = (value) => {


      // console.log(value)
      setStep(value)


  }
  return (
    <div>
      {
        loader && <Spinner/>
      }
      <Topbar />
      <Navbar datas={datas} />
      <div className='md:flex justify-center pt-5 px-12'>





<a href="/alumni-registration" target="_blank"  className='text-base p-2 md:p-3 w-full  border rounded-lg hover:bg-red-600 text-center hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-2 font-bold' onClick={() => { stepChanges("step1") }}>Alumni Registration Form (online)</a>
<button className='text-base p-2 md:p-3 w-full  border rounded-lg hover:bg-red-600 hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-2 font-bold' onClick={() => { stepChanges("step2") }}> List of Registered Alumni</button>

<a href="/alumni-feedback" target="_blank" className='text-base p-2 md:p-3 w-full text-center  border rounded-lg hover:bg-red-600 hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-2 font-bold' >Feedback Form</a>

</div>
{ step == "step1" ?
  <>

<div className="flex h-11/12 bg-black justify-center ">

<img className="object-contain" src={alumni} alt="alumni"/>
</div>
<div className='h-[20rem] flex mt-4  '>


<div class="overflow-x-auto   px-4">
  <table class="w-[30rem] border  divide-y-2 divide-gray-200 text-sm">
    <thead>
      <tr>
        <th
          class="whitespace-nowrap px-4 border  py-2 text-left font-medium text-gray-900"
          >
          Name
        </th>

        <th class="whitespace-nowrap px-4 border  py-2 text-left font-medium text-gray-900">Post</th>
      </tr>
    </thead>

    <tbody class="divide-y border  divide-gray-200">

          <tr>
                <td class="whitespace-nowrap border  px-4 py-2 font-medium text-gray-900">

                </td>
                <td class="whitespace-nowrap border  px-4 py-2">

                </td>

              </tr>


              <tr>
                <td class="whitespace-nowrap border  px-4 py-2 font-medium text-gray-900">

                </td>
                <td class="whitespace-nowrap border  px-4 py-2">

                </td>

              </tr>


    </tbody>
  </table>
</div>

                      </div>
                      </>:null
}
<div>
{
  step == "step3" ?
  <>
  <AlumniFeedBack/>
  </>:null
}
</div>
<div>
{
  step == "step2" ?
  <>
  <div onClick={() => { stepChanges("step1") }} className="text-base p-2 md:p-3 w-max  border rounded-lg hover:bg-red-600 hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-2 font-bold md:mx-16 my-4">Go back</div>
  <AlumniList/>
  </>:null
}
</div>
      <Footer datas={datas}  />
    </div>
  );
}

export default Alumni;
