import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../Footer'
import Topbar from '../landing/Topbar'
import Spinner from '../facultydashboard/formGroup/Spinner'
import { Link ,useParams } from 'react-router-dom'
function PgTable() {
  const semester = useParams()
  // console.log(semester)
  const [loader, setLoader]=useState(true)
  const [state, setState]=useState([])
  const getStudyMaterials = () => {
    axios.get(`/api/v1/studymaterial/${semester.semester} `)
        .then(function (response) {
            // handle success
            // console.log(response)
            setState(response.data.studyMaterial)
            setLoader(false)
            // console.log(response.data.userList);
        })
        .catch(function (error) {
            // handle error
            setLoader(false)
            console.log(error);
        })
}



useEffect(() => {
  getStudyMaterials()
}, [])
const [datas, setData] = React.useState({})

const siteData = () => {
  axios.get('/api/v1/api/site/data')
    .then(function (response) {
      // handle success
      // console.log(response);
      setData(response.data[0])
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
}
React.useEffect(() => {
  siteData()
}, [])
  return (
    <div>
      {
        loader && <Spinner/>
      }
        <Topbar/>
        <Navbar datas={datas}/>
        <div className=' h-[30rem]   grid justify-items-center'>


<div class="overflow-x-auto w-4/5 mt-4  ">
<table class="min-w-full divide-y-2  divide-gray-200 text-sm">
<thead>
<tr>
<th
  class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
  >
  Semester
</th>
<th
  class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
  >
  Topic Name
</th>

<th
  class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
>
  Uploaded By
</th>
<th
  class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
>
  Download
</th>
<th
  class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
>
  Date of Upload
</th>

<th class="px-4 py-2"></th>
</tr>
</thead>


{
                state.toReversed().map((a, ind) => {

                  return (
                    <>

                       <tbody class="divide-y divide-gray-200" key={ind}>
                          <tr>
                            <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">{a.semester}</td>

                            <td class="whitespace-nowrap  border px-4 py-2 font-medium text-gray-900">
                              {a.topicName}
                            </td>
                            <td class="whitespace-nowrap  border px-4 py-2 font-medium text-gray-900">
                              {a.topicName}
                            </td>
                            <td class="whitespace-nowrap  border px-4 py-2 font-medium text-gray-900">
                              <center>
                                <Link to={`/api/v1/download-study-material/${a.fileName}`} target="_blank" class="whitespace-nowrap  border px-4 py-2 text-gray-700"
                                  // onClick={() => { studyMaterialsDownload(a.fileName) }}

                                >Download</Link>
                              </center>
                            </td>

                            <td class="whitespace-nowrap border  px-4 py-2 text-gray-700">{new Date(a.Date).getDate() + " / " + new Date(a.Date).getMonth() + " / " + new Date(a.Date).getFullYear()}</td>


                          </tr>
                        </tbody>




                    </>
                  )
                })
              }

</table>
</div>
    </div>
        <Footer datas={datas}/>
    </div>
  )
}

export default PgTable
