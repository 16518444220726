import React, { useEffect, useState } from 'react'
import Footer from '../../Footer';
import Topbar from './Topbar';
import Navbar from '../../components/Navbar';
import axios from 'axios';

function Confrence() {
    const [imG ,setImG]= React.useState({});
    const [datas, setData] = React.useState({});
    const [loader, setLoader] = useState(true);
    // console.log(datas);
    const siteData = () => {
      axios
        .get("/api/v1/api/site/data")
        .then(function (response) {
          // handle success
          // console.log(response);
          setData(response.data[0]);
          setLoader(false);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    };
    React.useEffect(() => {
      siteData();
    }, []);
    const [dat,setDat] = useState([])
    const getGalleryData = ()=>{
      axios.get('/api/v1/find-file-galley')
    .then(function (response) {
      // handle success
      // console.log(response);
      setDat(response.data)

    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    }
    useEffect(()=>{
      getGalleryData()
    },[])
  return (
    <div>
        <Topbar />
      <Navbar datas={datas} />

      <>
      <h2 className="text-4xl text-center font-bold my-8 lg:text-5xl">Conference</h2>
          <section className="py-6 col-span-full bg-gray-100">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                {dat.map((i) => {
                  return (

                    <>
                    {
                      i.category === 'CONFERENCE' &&
                      <img
                      className="object-cover w-full bg-gray-500 aspect-square"
                      src={`/api/v1/gallery/${i.img}`}
                      />
                    }
                    </>
                  );
                })}
              </div>
            </div>
          </section>
        </>
        <Footer datas={datas} />
    </div>

  )
}

export default Confrence