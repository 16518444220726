import React,{useState} from 'react'
import uni1 from '../../assests/uni1.jpeg'
import uni2 from '../../assests/uni2.jpeg'
import uni3 from '../../assests/uni3.jpeg'
import uni4 from '../../assests/uni4.jpeg'
import uni5 from '../../assests/uni5.jpeg'
import uni16 from '../../assests/uni16.jpeg'
import uni17 from '../../assests/uni17.jpeg'
import uni18 from '../../assests/uni18.jpeg'
import uni19 from '../../assests/uni19.jpeg'
import { Link } from 'react-router-dom'
function Gallerypreview() {
    const campus = [uni1, uni2, uni3, uni4,  ]
    const eventImg = [ uni16, uni17, uni18, uni19, ]
    const [step, setStep] = useState('step2')
    // console.log(useState)
    // console.log(step)
    const stepChanges = (value) => {


        // console.log(value)
        setStep(value)


    }
  return (
    <div className='m-4'>
    <div className='flex justify-center  '>



<button className='text-xs p-2 md:p-5  border rounded-lg hover:bg-red-600 hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-5 font-bold' onClick={() => { stepChanges("step2") }}>Campus</button>

<button className='text-xs p-2 md:p-5  border rounded-lg hover:bg-red-600 hover:text-white hover:ring-red-700 hover:border-red-700 border-black m-5 font-bold' onClick={() => { stepChanges("step3") }}>Event</button>
</div>

{
        step == "step2" ? <>

            <section className="py-6 transition-all delay-1000 ease-in col-span-full bg-gray-100">
                <div className="container flex flex-col justify-center p-4 mx-auto">
                    <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                        {
                            campus.map((i) => {
                                return (
                                    <>
                                        <img className="object-cover w-full bg-gray-500 aspect-square" src={i} />
                                    </>
                                )
                            })
                        }


                    </div>
                </div>
            </section>

        </> : null
    }
    {
        step == "step3" ? <>

            <section className="py-6 col-span-full bg-gray-100">
                <div className="container flex flex-col justify-center p-4 mx-auto">
                    <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                        {
                            eventImg.map((i) => {
                                return (
                                    <>
                                        <img className="object-cover w-full bg-gray-500 aspect-square" src={i} />
                                    </>
                                )
                            })
                        }


                    </div>
                </div>
            </section>
        </> : null
    }
<div className='flex justify-center'>

<Link
  class="group relative rounded-md  inline-block overflow-hidden border border-red-600 px-8 py-3 focus:outline-none focus:ring"
  to="/gallery"
>
  <span
    class="absolute inset-x-0 bottom-0 h-[2px] bg-red-600 transition-all group-hover:h-full group-active:bg-red-500"
    ></span>

  <span
    class="relative text-sm font-medium text-red-600 transition-colors group-hover:text-white"
    >
    View More
  </span>
</Link>
      </div>


    </div>
  )
}

export default Gallerypreview